<template>
    <div class="Profile-support">
        <div class="Profile-support__main-block" v-if="isHiddenMain">
            <div class="Profile-support__title">
                {{ $t('profile.support.title') }}
            </div>
            <div class="Profile-support__cards">
                <div class="support-card" @click="isHiddenAccount = true; isHiddenMain = false">
                    <img src="/images/profile/support-account.svg" width="90" height="90">
                    <div class="support-card__title">
                        {{ $t('profile.support.account-item') }}
                    </div>
<!--                    <div class="support-card__desc">-->
<!--                        {{ $t('profile.support.leave-feedback') }}-->
<!--                    </div>-->
                </div>
                <div class="support-card" @click="isHiddenAccount = false; isHiddenMain = false; isHiddenTraining = true">
                    <img src="/images/profile/support-dairy.svg" width="90" height="90">
                    <div class="support-card__title">
                        Обучение
                    </div>
<!--                    <div class="support-card__desc">-->
<!--                        {{ $t('profile.support.leave-feedback') }}-->
<!--                    </div>-->
                </div>
            </div>

            <div class="Profile-support__request support-request">
                <div class="support-request__icon">
                    <img src="/images/profile/support-question.svg" width="105" height="105">
                </div>
                <div class="support-request__desc-block">
                    <div>
                        <div class="support-request__question">
                            {{ $t('profile.support.cant-find') }}
                        </div>
                        <div class="support-request__answer">
                            {{ $t('profile.support.write-request') }}
                        </div>
                    </div>
                    <button class="support-request__btn" @click="isHiddenReguestForm = true; isHiddenMain = false">
                        {{ $t('profile.support.write-btn') }}
                    </button>
                </div>
            </div>
        </div>
      <div class="Profile-support__questions-block" v-if="isHiddenTraining">
        <div class="d-flex flex-row align-items-center">
          <button class="questions__back-btn" @click=" isHiddenTraining = false; isHiddenMain = true">
            <img src="/images/profile/arrow-back.svg" width="9" height="15">
          </button>
          <div class="questions__title">
            Обучение
          </div>
        </div>

        <div id="accordion" class="questions__accordion accordion" role="tablist">
          <div class="card" v-for="(data, key) in accordionDataTraining" :key="data.id">
            <div class="card-header"
                 v-b-toggle="accordion = 'collapse' + key"
                 :id="'heading' + key"
                 role="tab" >
              <h5 class="mb-0">
                <button class="btn btn-link"
                        :class="{'active': accordion == 'collapse' + key}"
                        data-toggle="collapse"
                        :data-target="'#collapse' + key"
                        aria-expanded="false"
                        :aria-controls="'collapse' + key">
                  {{ $t(data.title) }}
                </button>
              </h5>
            </div>
            <b-collapse :id="'collapse' + key"
                        role="tabpanel"
                        :class="{'show': accordion == 'collapse' + key}"
                        class="collapse"
                        :aria-labelledby="'heading' + key"
                        data-parent="#accordion">
              <div class="card-body pt-0">
                <hr class="mt-0">
                <div v-html="$t(data.content)"></div>
              </div>
            </b-collapse>
          </div>
        </div>
      </div>
        <div class="Profile-support__questions-block" v-if="isHiddenAccount">
            <div class="d-flex flex-row align-items-center">
                <button class="questions__back-btn" @click="isHiddenAccount = false; isHiddenMain = true">
                    <img src="/images/profile/arrow-back.svg" width="9" height="15">
                </button>
                <div class="questions__title">
                    {{ $t('profile.support.account-item') }}
                </div>
            </div>

            <div id="accordion" class="questions__accordion accordion" role="tablist">
                <div class="card" v-for="(data, key) in accordionData" :key="data.id">
                    <div class="card-header" 
                        v-b-toggle="accordion = 'collapse' + key" 
                        :id="'heading' + key" 
                        role="tab" >
                        <h5 class="mb-0">
                            <button class="btn btn-link" 
                                :class="{'active': accordion == 'collapse' + key}" 
                                data-toggle="collapse" 
                                :data-target="'#collapse' + key" 
                                aria-expanded="false" 
                                :aria-controls="'collapse' + key">
                            {{ $t(data.title) }}
                            </button>
                        </h5>
                    </div>
                    <b-collapse :id="'collapse' + key" 
                        role="tabpanel"
                        :class="{'show': accordion == 'collapse' + key}" 
                        class="collapse" 
                        :aria-labelledby="'heading' + key" 
                        data-parent="#accordion">
                        <div class="card-body pt-0">
                            <hr class="mt-0">
                            <div v-html="$t(data.content)"></div>
                        </div>
                    </b-collapse>
                </div>
            </div>
        </div>

        <div class="Profile-support__request-form" v-if="isHiddenReguestForm">
            <form>
                <div class="request-form__header">
                    <div class="request-form__header-title">
                        {{ $t('profile.support.thema') }}
                    </div>
                    <input type="text" class="request-form__header-input">
                </div>
                <div class="request-form__content">
                    <b-form-textarea
                        class="request-form__textarea"
                        v-model="request"
                        placeholder=""
                        no-resize
                    ></b-form-textarea>
                </div>
                <div class="request-form__footer">
                    <div class="uploaded-files">
                        <div class="uploaded-files__pair" >
                            <div v-for="(image, i) in images" class="uploaded-file">
                                <button @click="removeImage(i)" class="uploaded-file__delete" type="button">
                                    <img src="/images/close.svg" width="8" height="8">
                                </button>
                                <div class="uploaded-file__title">
                                  {{ imagesName[i] }}
                                </div>
                                <img src="/images/profile/file.svg" class="uploaded-file__img">
                            </div>
                        </div>

                    </div>

                    <div class="d-flex flex-row">
                      <label style="margin-bottom: 0" for="file-upload" class="custom-file-upload request-form__send-btn">
                        Загрузить файл
                      </label>
                        <input id="file-upload" type="file" @change="onFileChange">





                        <button class="request-form__send-btn">
                            {{ $t('profile.support.send-btn') }}
                        </button>
                        <button class="request-form__cancel-btn" @click="isHiddenReguestForm = false; isHiddenMain = true">
                            {{ $t('profile.support.cancel-btn') }}
                        </button>
                    </div>
                </div>
            </form>
        </div>






        

    </div>
</template>

<script>
import Vue from 'vue';


export default {
    components: {
    },
    data(){
        return {
          imagesName:[],
          images:[],
            isHiddenTraining:false,
            isHiddenMain: true,
            isHiddenAccount: false,
            isHiddenReguestForm: false,
            request: '',
            accordion: '',
          accordionDataTraining: [
            {
              'id': "1",
              'title': 'support.обучение.Предметы6',
              'content': 'support.обучение.Предметы6Ответ',
            },
            {
              'id': "2",
              'title': 'support.обучение.Предметы7',
              'content': 'support.обучение.Предметы7Ответ',
            },
            {
              'id': "3",
              'title': 'support.обучение.ПроцессОбучения',
              'content': 'support.обучение.ПроцессОбученияОтвет',
            },

          ],
            accordionData: [
                {
                'id': "1",
                'title': 'support.личныйКабинет.востановитьПароль',
                'content': 'support.личныйКабинет.востановитьПарольОтвет',
                },

            ],
            fileList: [],

        }
    },

    methods: {
      onFileChange(e) {
        if (this.images.length < 5) {
          console.log(this.imagesName)
          var fileData = event.target.files[0];
          this.createImages(e.target.files || e.dataTransfer.files, fileData.name ? fileData.name : "");
        } else {
          return
        }
        e.target.reset();
      },
      createImages(files, filenames) {
        [...files].forEach(file => {
          var extArray = filenames.split(".");
          var ext = extArray[extArray.length - 1];
          if (ext != 'pdf' & ext != 'jpeg' & ext != 'png' & ext != 'gif' & ext != 'pptx' & ext != 'docx'& ext != 'doc') {
            Vue.toastr({
              message: this.$t('signup.error'),
              description: this.$t('signup.file-error'),
              type: 'error'
            });
            return
          }
          this.imagesName.push(filenames);
          const reader = new FileReader();
          reader.onload = e => this.images.push(e.target.result);
          reader.readAsDataURL(file);
        });
      },
      removeImage(index) {
        this.images.splice(index, 1);
        this.imagesName.splice(index, 1);
      },
    }
}
</script>

<style lang="less" scoped>
input[type="file"] {
  display: none;
}
.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}
.Profile-support__main-block {
    max-width: 1000px;
    // margin: 0 auto;
}
.Profile-support__title {
    color: #473F95;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    padding: 16px;
    background-color: #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04), 0px 4px 16px rgba(0, 0, 0, 0.08);
    text-align: center;
    margin: 0 auto 59px auto;
    width: fit-content;
    border-radius: 8px;
}   
.Profile-support .Profile-support__cards {
    margin-bottom: 30px;
}
.Profile-support__cards {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.Profile-support__cards .support-card {
    margin-right: 30px;
} 
.Profile-support__cards .support-card:last-child {
    margin-right: 0px;
} 
.support-card {
    padding: 20px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04), 0px 4px 16px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    text-align: center;
    background-color: #fff;
    max-width: 314px;
  min-width: 200px;
    cursor: pointer;
}
.support-card__title {
    color: #473F95;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    padding-top: 28px;
    padding-bottom: 12px;
    text-align: center;
}
.support-card__desc {
    color: #000000;
    font-size: 14px;
    line-height: 16px;
}
.Profile-support__request {
    padding: 30px 0 45px 0;
}
.support-request {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
}
.support-request__icon {
    padding: 22px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04), 0px 4px 16px rgba(0, 0, 0, 0.08);
    border-radius: 50%;
    width: fit-content;
    // z-index: 2;
    position: absolute;
}
.support-request__desc-block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 32px 60px 32px 100px;
    background-color: #fff;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04), 0px 4px 16px rgba(0, 0, 0, 0.08);
    border-radius: 0px 60px 60px 0px;
    height: fit-content;
    margin-left: -40px;
    // z-index: 1;
    margin-left: 80px;
    width: 100%;
    max-width: 785px;
}
.support-request__question {
    color: #000000;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 11px;
}
.support-request__answer {
    color: #D23168;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
}
.support-request__btn {
    background: #473F95;
    border-radius: 5px;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    padding: 12px 36px;
    height: fit-content;
    border: none;
    color: #fff;
}
 .Profile-support__questions-block {
    max-width: 990px;
    margin: 0 auto;
 }
.questions__title {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: #473F95;
}
.questions__back-btn {
    display: flex;
    border: none;
    background: #473F95;
    border-radius: 5px;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
}
.questions__accordion {
    padding: 35px 0;
}
.questions__accordion .card {
    border-radius: 10px;
    box-shadow: 5px 10px 50px rgba(108, 100, 190, 0.15);
    margin-bottom: 5px;
    border: none;
}
.questions__accordion .card-header {
    background: #ffffff;
    border-radius: 10px;
    border: none;
}
.questions__accordion .card-body {
    font-weight: lighter;
}
.questions__accordion .card .btn {
    color: #000;
    font-size: 20px;
    line-height: 23px;
    font-family: 'Roboto Condensed';
    position: relative;
    padding-left: 24px;
}
.questions__accordion .card .btn:hover {
    text-decoration: none;
}
.questions__accordion .card .btn:focus,
.questions__accordion .card .btn:active {
    outline: none;
    box-shadow: none;
    text-decoration: none;
}
.questions__accordion .card .btn:after {
    content: " ";
    display: block;
    position: absolute;
    width: 8px;
    height: 18px;
    background-image: url("/images/accordion-arrow.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    top: 50%;
    left: 0px;
    margin-top: -9px;
    transition: all 0.2s ease;
}
.questions__accordion .card .btn:after {
    transform: rotate(0deg);
}
.questions__accordion .card .not-collapsed .btn.active:after {
    transform: rotate(90deg);
}
.questions__accordion .card .btn[aria-expanded=true]:after {
    transform: rotate(90deg);
    transition: all 0.2s ease;
}
.Profile-support__request-form {
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04), 0px 4px 16px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    padding: 32px;
    max-width: 1002px;
    margin: 0 0 130px 0;
    font-family: Roboto;
}
.request-form__header {
    display: flex;
    flex-direction: row;
    margin-bottom: 32px;
}
.request-form__header-title {
    color: #979797;
    font-size: 18px;
    line-height: 21px;
    margin-right: 24px;
}
.request-form__header-input {
    width: 100%;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 2px solid #E1E1E1;
    outline: none;
    color: #303030;
    font-size: 14px;
    line-height: 16px;
    padding-bottom: 12px;
    padding-top: 4px;
}
.request-form__header-input::placeholder {
    color: #303030;
    font-size: 14px;
    line-height: 16px;
}
.request-form__textarea {
    padding: 16px;
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    border-radius: 4px;
    color: #303030;
    font-size: 13px;
    line-height: 15px;
    height: 348px;
    box-shadow: none;
}
.request-form__footer {
    margin-top: 20px;
}
.request-form__attachment-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: none;
    background-color: #fff;
    color: #303030;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 24px;
}
.request-form__send-btn,
.support-upload__download {
    font-size: 15px;
    line-height: 18px;
    font-family: Inter;
    padding: 14px 36px;
    margin-right: 15px;
    color: #fff;
    border: 1px solid #473F95;
    background-color: #473F95;
    border-radius: 5px;
}
.request-form__cancel-btn {
    font-size: 15px;
    line-height: 18px;
    font-family: Inter;
    padding: 13px 36px;
    color: #473F95;
    border: 1px solid #473F95;
    background-color: #fff;
    border-radius: 5px;
}
/deep/ .el-upload-dragger {
    // min-width: 480px;
    min-height: 304;
    height: auto;
    padding: 32px;
    font-family: Roboto;
    width: 100%;
}
/deep/ .el-upload-dragger:hover {
    border-color: #473F95;
}
/deep/ .el-icon-document:before {
    content: url("/images/profile/file.svg");
}
/deep/ .el-upload-list__item-name {
    display: flex;
    align-items: center;
    color: #303030;
    font-size: 16px;
    line-height: 19px;
}
/deep/ .el-upload-list__item.is-success .el-upload-list__item-name:focus, 
/deep/ .el-upload-list__item.is-success .el-upload-list__item-name:hover {
    color: #303030;
    cursor: default;
}
/deep/ .el-upload--picture-card:hover, 
/deep/ .el-upload:focus {
    border-color: #473F95;
    color: #473F95;
}

.Profile-support__upload {
    padding-top: 52px;
}
.support-upload {
    text-align: center;
}
.support-upload__tip {
    color: #AEAEAE;
    font-size: 18px;
    line-height: 150%;

}
.support-upload__drag-and-drop {
    color: #303030;
    font-size: 18px;
    line-height: 150%;
    padding: 20px 0;
}
.support-upload__select {
    color: #D23168;
    font-weight: bold;
    font-size: 22px;
    line-height: 26px;
}
.modal-upload-file {
    position: relative;
}
.modal-upload-file__btn {
    position: absolute;
    background: #473F95;
    border-radius: 100px;
    height: 44px;
    width: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 12px;
    right: 12px;
    min-width: fit-content;
    padding: 0;
}
/deep/ .el-upload-list__item-status-label {
    top: 10px;
}
/deep/ .el-upload-list__item .el-icon-close {
    top: 15px;
}
/deep/ .el-upload-list__item-name  {
    padding-top: 5px;
}
.uploaded-files {
    padding-top: 13px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}
.uploaded-files .uploaded-file {
    margin-right: 30px;
}
.uploaded-files .uploaded-files__pair:last-child .uploaded-file:last-child {
    margin-right: 0;
}
.uploaded-file {
    width: 200px;
    position: relative;
    background: #FFFFFF;
    border: 2px solid #473F95;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 8px 8px 12px 8px;
    text-align: center;
    margin-bottom: 28px;
}
.uploaded-file__delete {
    position: absolute;
    width: 24px;
    height: 24px;
    background: #D23168;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    top: -16px;
    right: -16px;
}
.uploaded-file__title {
  text-overflow: ellipsis;
    max-width: 180px;
  overflow: hidden;
  white-space: nowrap;
    color: #473F95;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    padding-bottom: 6px;
}
.uploaded-files__pair {
    display: flex;
    flex-direction: row;
}
@media (max-width: 767px) {
    .Profile-support .Profile-support__cards {
        flex-direction: column;
        align-items: center;
        margin-bottom: 0px;
    }
    .Profile-support__cards .support-card {
        margin-right: 0;
        margin-bottom: 24px;
    }
    .Profile-support__request {
        margin-bottom: 0px;
        padding-bottom: 0;
        margin-top: 82px;
    }
    .support-request {
        flex-direction: column;
    }
    .support-request__question {
        margin-bottom: 23px;
    }
    .support-request__icon img {
        width: 84px;
        height: 84px;
    }
    .support-request__icon {
        padding: 18px; 
        top: -30px;
    }
    .support-request__desc-block {
        margin-left: 0;
        border-radius: 8px;
        padding: 84px 24px 40px 24px;
        flex-direction: column;
        text-align: center;
    } 
    .support-request__btn {
        margin-top: 40px;
    }
    #accordion {
        padding: 30px 0 10px 0;
    }
    .questions__accordion .card .btn {
        padding-right: 0;
    }
    .Profile-support__request-form {
        padding: 16px 16px 32px 16px;
    }
    .request-form__footer {
        margin-top: 32px;
    }
    .request-form__attachment-btn {
        margin-bottom: 36px;
    }
    .uploaded-files {
        flex-direction: column;
    }
    .uploaded-file {
        min-width: 120px;
    }
    .request-form__send-btn, 
    .support-upload__download {
        padding: 13px 12px;
        width: 100%;
    }
    .request-form__send-btn {
        max-width: 152px;
    }
    .Profile-support__request-form {
        margin-bottom: 10px;
    }
}

</style>